<template>
  <div>
    <v-container>
      <h2 class="text-h5 mb-4">Joomla Queues</h2>

      <v-data-table
        :headers="headers"
        :items="queues"
        :search="search_keyword"
        :items-per-page="15"
        class="elevation-1 mt-2"
      >
        <template v-slot:top>
          <v-row style="padding: 10px">
            <v-col md="2" cols="6"> <strong>Total: </strong> {{ queues.length }} </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="search_keyword"
                label="Search Keyword"
                outlined
                dense
                placeholder="Search Keyword"
                @change="searchOrders()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn depressed color="primary" @click="increaseShopPriority">Increase Priority</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-if="!items.length">
              <td colspan="7" class="text-center" style="padding: 20px 0px">
                <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
                <p style="font-size: 25px">No Data Found!!</p>
              </td>
            </tr>
            <tr v-else v-for="item in items" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.command }}</td>
              <td>{{ item.iArgument1 }}</td>
              <td>{{ item.iArgument2 }}</td>
              <td>{{ item.priority }}</td>
              <td>{{ item.subscribed_package }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white" v-if="item.is_processing == '1'"
                  >Processing</v-chip
                >
                <v-chip small class="ma-2" color="secondary" text-color="white" v-else>Waiting</v-chip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mdiEye } from '@mdi/js'
export default {
  name: 'JoomlaQueues',
  data() {
    return {
      loading_data: false,
      search_keyword: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        { text: 'Command', value: 'command' },
        { text: 'Shop ID', value: 'iArgument1' },
        { text: 'Arg 2', value: 'iArgument2' },
        { text: 'Priority', value: 'priority' },
        { text: 'Package', value: 'subscribed_package' },
        { text: 'Status' },
      ],
      queues: [],

      icons: {
        mdiEye: mdiEye,
      },
      is_first: true,
      interval: null,
    }
  },
  mounted() {
    this.fetchQueues()
    this.interval = setInterval(() => {
      this.fetchQueues()
    }, 6000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    async fetchQueues() {
      if (this.is_first == true) this.loading_data = true
      try {
        let response = await axios.get('/admin/fetch-joomla-queues')
        if (response.data.success) {
          this.queues = response.data.jobs
          this.is_first = false
        }
      } catch (e) {
      } finally {
        this.loading_data = false
      }
    },

    showDetails(item) {
      this.queue = item
      this.show_details = true
    },

    async increaseShopPriority() {
      try {
        console.log('HELO')
        let response = await axios.post(`/admin/increase-queue-priority`, {
          shop_id: this.search_keyword,
        })
        if (response.data.success) {
          this.$swal.fire('Done', 'Priority Increased', 'success')
          this.fetchQueues()
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
  },
}
</script>